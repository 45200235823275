import React from "react";
import gsap from "gsap";

export function RichTextLinkAnimator({}) {
    const DURATION_IN_MS = 600;
    const settings = {
        duration: DURATION_IN_MS / 1000,
        ease: "var(--animation-ease)"
    };

    React.useEffect(() => {
        const links = document.querySelectorAll(".header-animation__text a");

        let isPlayingEnter = [];
        let isPlayingLeave = [];

        links.forEach(link => {
            const original = link.querySelector(".original");
            const underline = link.querySelector(".underline");

            link.addEventListener("mouseenter", () => {
                if (isPlayingEnter.includes(link)) return;

                // Clone the internal span.
                const clone = original.cloneNode(true);
                clone.classList.remove("original");
                clone.classList.add("clone");
                link.appendChild(clone);
                isPlayingEnter = [...isPlayingEnter, link];

                gsap.fromTo(clone, { y: "100%" }, { y: 0, ...settings });
                gsap.fromTo(original, { y: 0 }, { y: "-100%", ...settings });

                setTimeout(() => {
                    isPlayingEnter = isPlayingEnter.filter(
                        item => item !== link
                    );
                }, DURATION_IN_MS);
            });

            link.addEventListener("mouseleave", () => {
                if (isPlayingLeave.includes(link)) return;
                isPlayingLeave = [...isPlayingLeave, link];
                const clone = link.querySelector(".clone");
                gsap.fromTo(clone, { y: 0 }, { y: "-100%", ...settings });
                gsap.fromTo(original, { y: "100%" }, { y: 0, ...settings });

                setTimeout(() => {
                    clone.remove();
                    isPlayingLeave = isPlayingLeave.filter(
                        item => item !== link
                    );
                }, DURATION_IN_MS);
            });
        });
    }, []);

    return null;
}
